@import "vendor/normalize";
@import "vendor/bourbon/bourbon";

//neat variables

// $visual-grid: true
// $visual-grid-color: red
// $visual-grid-index: front
// $visual-grid-opacity: 0.1

$max-width: em(1200) !default;

@import "vendor/neat/neat";
@import "vendor/font-awesome/font-awesome";
@import "vendor/slick/slick";
@import "vendor/slick/slick-theme";
@import "vendor/alertify.core";
@import "vendor/alertify.default";
@import "vendor/validationEngine";
@import "vendor/rrssb";
@import "vendor/remodal";
@import "vendor/slicknav";
@import "vendor/sweetalert";
@import "vendor/photoswipe/photoswipe";
@import "vendor/photoswipe/default-skin";
@import "vendor/chosen";

@import "config";
@import "variables";
@import "base";
@import "helpers";
@import "layout";
@import "modules";
@import "misc";
