html, button, input, select, textarea {
  font-family: arial, sans-serif;
  color: #222;
}

html {
  font-size: 1em;
  line-height: 1.4;
  -webkit-font-smoothing: antialiased;
}

body {
  font-family: $primary-font;
}

h1, h2, h3, h4, h5, h6, p {
  margin: 0;
  padding: 0;
}

h1,
h2 {
  font-weight: $bold;
  text-transform: uppercase;
}

h1 {
  font-size: 24px;

  @media screen and (max-width: $tablet) {
    font-size: 18px;
  }
}

h2 {
  margin: 32px 0;
  font-size: 16px;
  color: #333;
}

p {
  margin-bottom: 20px;
  font-size: 14px;
  line-height: 24px;
  color: #666;
}

a {
  text-decoration: none;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

::-moz-selection, ::selection {
  background: #b3d4fc;
  text-shadow: none;
}

hr {
  display: block;
  clear: both;
  height: 1px;
  margin: 32px 0;
  border: 0;
  border-top: 3px solid #333;
  margin: 1em 0;
  padding: 0;
}

audio, canvas, img, video {
  vertical-align: middle;
}

fieldset {
  border: 0;
  margin: 0;
  padding: 0;
  position: relative;
}

textarea {
  resize: none;
}

figure {
  margin: 0;
}

input[type="text"],
textarea {
  width: 100%;
  height: 40px;
  padding: 0 10px;
  line-height: 40px;
  font-size: 12px;
  font-weight: bold;
  font-family: $primary-font;
  color: #333;
  border-radius: 4px;
  border: 1px solid black;
}

textarea {
  line-height: 16px;
  padding: 10px;

  @include placeholder {
    color: #999;
    font-weight: bold;
  }
}
